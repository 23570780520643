<template>
  <div class="master-toko">
    <header-page>
      <span class="size14 fw-bold-700" style="color: #0B5FFF;margin-right: 5px;">Master</span>
      <span class="text-dark text-darken-5" style="margin-right: 5px;">></span>
      <span class="text-dark fw-bold-400 size14">Pengguna</span>
      <template v-slot:action>
        <div class="w-75">
          <b-input-group class="input-group-merge">
            <b-form-input v-model="filter.search" class="border-0" placeholder="Search" />
            <b-input-group-append>
              <b-input-group-text class="border-0">
                <feather-icon icon="SearchIcon" />
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </div>
      </template>
    </header-page>

    <b-container fluid v-if="isLoading == false" class="mt-2">
      <b-row>
        <b-col cols="12">
          <h4 class="text-black-2 fw-bold-700 size16 mb-1">
            Daftar Pengguna
          </h4>
        </b-col>
        <b-col v-for="(user, index) in result.data" :key="index" cols="12" md="6" lg="6" class="mb-1">
          <b-card class="px-16 py-16 border-12 mb-0" no-body>
            <div class="d-flex flex-column">
              <div class="d-flex mb-2 justify-content-between">
                <div class="">
                  <h3 class="text-black-2 fw-bold-700 size20 mb-1">
                    {{ user.name }}
                  </h3>
                  <span class="text-dark text-darken-4 fw-bold-600 size14 mb-0">
                    {{ user.email }}
                  </span>
                </div>
                <b-img width="100" height="100" style="object-fit:cover;" class="ml-1" rounded="circle"
                  :src="user.photo_url" />
              </div>
              <div class="d-flex">
                <b-button v-if="checkPermission('update user')" class="bg-white mr-1 px-3 btn-block"
                  style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);" @click="editItem(user)">
                  <b-img :src="require('@/assets/images/icons/Process2.svg')" /> Ubah
                </b-button>
                <b-button v-if="checkPermission('delete user')" class="border-0 border-8 btn-delete bg-pink p-1"
                  @click="deleteData(user.id)">
                  <feather-icon icon="Trash2Icon" size="18" class="text-danger" />
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" lg="6" class="mb-1">
          <b-button v-if="checkPermission('add user')" class="card px-3 py-18 border-12 mb-0 bg-white w-100"
            style="height:199px" @click="addUser">
            <div class="d-flex flex-column align-items-center justify-content-center h-100 w-100">
              <h6>
                <feather-icon icon="PlusIcon" size="28" />
              </h6>
              <h6 class="text-dark fw-bold-800 mb-0">
                Tambah pengguna baru ( {{ result.total }}/{{ user_slot }})
              </h6>
            </div>
          </b-button>
        </b-col>
        <b-col cols="12">
          <pagination class="mb-0 mt-2" :data="result" :limit="4" align="center" size="large"
            @pagination-change-page="getData" />
        </b-col>
      </b-row>
    </b-container>

    <b-col cols="12" class="mt-5">
      <div v-if="isLoading == true" class="d-flex justify-content-center mb-1">
        <b-spinner label="Loading..." />
      </div>
    </b-col>

    <ModalUsers :form-data="formPayload" :create-item="createItem" :edit-id="editId" :edit-item="editItem"
      :update-item="updateItem" :clean-up-form="cleanUpForm" :logo="logo"
      :handle-phone-number-input="handlePhoneNumberInput" @getPayload="getPayload" />

  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BContainer, BButton, BImg, VBToggle, BRow, BCol, BCard, BFormInput, BInputGroup, BInputGroupAppend, BSpinner, BInputGroupText,
} from 'bootstrap-vue'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import HeaderPage from '@/components/HeaderPage.vue'
import ModalUsers from '@/components/Master/Users/Modal/ModalUsers.vue'

export default {
  title() {
    return 'Master Pengguna'
  },
  components: {
    BButton,
    HeaderPage,
    BImg,
    BRow,
    BCol,
    BContainer,
    BCard,
    BFormInput,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    ModalUsers,
    BInputGroupText,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    const { merchant } = JSON.parse(localStorage.getItem('userData'))
    const { user_slot } = merchant
    return {
      user_slot,
      isLoading: false,
      result: {},
      editId: null,
      logo: null,
      formPayload: {
        name: '',
        description: '',
        logo: '',
        branch_uuid: '',
        roles: [],
        permissions: [],
      },
      filter: {
        search: '',
        per_page: 10,
      },
    }
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    addUser() {
      this.formPayload = {
        name: '',
        description: '',
        logo: '',
        branch_uuid: '',
        roles: [],
        permissions: [],
        phone: '62'
      }

      if (this.result.total >= this.user_slot) {
        this.$router.push({ name: 'master-billing.index', query: { cta: 'modal-billing-branch-and-user' } })
        return;
      }

      this.$bvModal.show('modal-gudang')
    },
    handlePhoneNumberInput(value) {
      if (!value) {
        this.formPayload.phone = '62';
      }
    },
    getPayload(value) {
      this.formPayload = value
    },
    cleanUpForm() {
      this.editId = null
      this.logo = null
      this.formPayload = {
        name: '',
        address: '',
        city: '',
        logo: '',
        branch_uuid: '',
      }
    },
    editItem(item) {
      this.cleanUpForm()
      this.editId = item.id
      this.logo = item.photo_url
      this.$store.dispatch('users/getData', {
        uuid: item.id,
        params: '',
      }).then(result => {
        this.isLoading = false
        this.formPayload = {
          name: result.data.data.name,
          email: result.data.data.email,
          phone: result.data.data.phone,
          jabatan: result.data.data.jabatan,
          branch_uuid: result.data.data.branch,
          roles: result.data.data.roles,
          permissions: result.data.data.permissions,
        }
        this.$store.commit('users/setFormPayload', {
          name: result.data.data.name,
          email: result.data.data.email,
          phone: result.data.data.phone,
          jabatan: result.data.data.jabatan,
          branch_uuid: result.data.data.branch,
          roles: result.data.data.roles,
          permissions: result.data.data.permissions,
        })
        this.$bvModal.show('modal-gudang')
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      this.$store.dispatch('users/getData', {
        uuid: '',
        params: queryParams,
      }).then(result => {
        this.isLoading = false
        this.result = result.data.data
        console.log(this.result, 'result');
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async createItem() {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin membuat Pengguna baru?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const form = this.preparePayload()
          this.isLoading = true
          this.$store.dispatch('users/postData', {
            params: '',
            payload: form,
          })
            .then(async () => {
              await this.getProfiles()
              await this.getData()
              successNotification(this, 'Success', 'User berhasil dibuat')
              this.cleanUpForm()
              this.$bvModal.hide('modal-gudang')
              this.isLoading = false
              window.location.href = '/master/users'
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
              this.isLoading = false
            })
        }
      });
    },
    async updateItem() {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin mengubah data Pengguna ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const form = this.preparePayload()
          this.isLoading = true
          form.append('_method', 'PATCH')

          this.$store.dispatch('users/postData', {
            params: `/${this.editId}`,
            payload: form,
          })
            .then(async () => {
              await this.getProfiles()
              await this.getData()
              successNotification(this, 'Success', 'Pengguna berhasil di ubah!')
              this.cleanUpForm()
              this.isLoading = false
              this.$bvModal.hide('modal-gudang')
              // window.location.href = '/master/users'
            })
            .catch(error => {
              if (error.response.data.meta.validations) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
              this.isLoading = false
            })
        }
      });
    },
    async deleteData(uuid) {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus Pengguna ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('users/deleteData', {
            uuid,
          })
            .then(() => {
              this.getData()
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Pengguna berhasil dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    getProfiles() {
      this.$store.dispatch('profile/getProfile')
        .then(response => {
          // const userPermission = JSON.stringify(response.data.data.permission)
          // localStorage.setItem('userPermission', userPermission)
          this.$store.commit('profile/SET_JABATAN', response.data.data.jabatan)
          localStorage.setItem('jabatan', response.data.data.jabatan)
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key]) {
          if (key !== 'branch_uuid' && key !== 'roles' && key !== 'permissions') {
            form.append(key, this.formPayload[key])
          }
          if (key === 'branch_uuid') {
            form.append(key, this.formPayload[key].uuid)
          }
        }
      }
      // Prepare roles payload
      if (this.formPayload.roles.length > 0) {
        this.formPayload.roles.forEach(item => {
          form.append('roles[]', item)
        })
      }
      // Prepare permissions payload
      if (this.formPayload.permissions.length > 0) {
        this.formPayload.permissions.forEach(item => {
          form.append('permissions[]', item)
        })
      }
      return form
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}


.master-toko {
  height: 100vh
}
</style>
